$(function () {
    var activeurl = window.location;
    $('a[href="' + activeurl + '"]').parent('li').addClass('active');

    function scrollEvent() {
        if (window.pageYOffset > 300) {
            $('#topPage').fadeIn();
        } else {
            $('#topPage').fadeOut();
        }

        if (window.pageYOffset > 0) {
            $('.add').hide();
        } else {
            $('.add').show();
        }
    }

    $('#topPage').click(function () {
        $('html, body').animate({scrollTop: 0}, 800);
        return false;
    });

    scrollEvent();

    $(window).scroll(function () {
        scrollEvent();
    });

    $("#open_nav").on({
        click: function () {
            $('body').css('overflow', 'hidden');
            $('#sidenav').toggle('slide', {direction: 'right'}, 300);
            $('#sidenav .nav .nav-link').delay(300).fadeIn(300);
            $('#close_btn').delay(300).fadeIn(300);
        }
    });

    $("#close_btn").on({
        click: function () {
            $('body').css('overflow', 'auto');
            $('#sidenav').hide('slide', {direction: 'right'}, 300);
            $('#sidenav .nav .nav-link').delay(300).fadeOut(300);
            $('#close_btn').delay(300).fadeOut(300);
        }
    });
});
